/* eslint-disable react/jsx-filename-extension */
import './public-path';
import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';
import '@ui5/webcomponents/dist/Assets.js';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { eventBus, setThemeId, getThemeId } from './common/eureka';
import './common/init';
import './styles/index.less';
import { Loader } from '@googlemaps/js-api-loader';

// Polyfill function to add pointerType to mouse events in Firefox
function addPointerTypeToMouseEvent(event) {
  if (event.pointerType === undefined && event instanceof MouseEvent) {
    event.pointerType = 'mouse';
  }
}

// Attach the polyfill to relevant events
document.addEventListener('click', addPointerTypeToMouseEvent, true);
document.addEventListener('mousedown', addPointerTypeToMouseEvent, true);
document.addEventListener('mouseup', addPointerTypeToMouseEvent, true);
document.addEventListener('mousemove', addPointerTypeToMouseEvent, true);

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
}

/* istanbul ignore next */
eventBus.on('appearance-update', settings => {
  console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
  const { themeChanged, themeId } = settings;
  if (themeChanged) {
    setThemeId(themeId);
    setTheme(getThemeId());
  }
});

eventBus.on('load-maps-api', (options, cb) => {
  new Loader(options).load().then(cb);
});

window.scan = function (code) {
  window.dispatchEvent(new KeyboardEvent('keypress', { key: code }));
  window.dispatchEvent(new KeyboardEvent('keypress', { keyCode: 13, key: 'Enter' }));
};

ReactDOM.render(<App {...axiosWrapper()} />, document.getElementById('eureka-app'));
